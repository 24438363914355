<template>
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <b-row>
            <LayoutHeader />
            <b-col lg="9" class="profile-center">
              <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('hotel-image') }}</h4>
                </template>
                <template v-slot:body>
                    <div class="imgs-container" :class="hotelImgs.length > 1 ? 'active':''">
                        <div class="add-new-img rounded mb-1 d-flex justify-content-center align-items-center flex-column position-relative">
                            <i class="fs-1 ri-add-line"></i>
                            <span>{{ $t('upload-image') }}</span>
                            <input
                              type="file"
                              multiple
                              class="position-absolute h-100 w-100"
                              style="inset: 0; opacity: 0; cursor: pointer;"
                              @change="getImg($event)"
                              placeholder="Upload Img"
                            >
                        </div>
                        <div class="img position-relative" v-for="(img,index) in imgsList" :key="index" >
                            <ul class="list-unstyled position-absolute d-flex align-items-center justify-content-center" style="top: 15px; left: 15px; gap: 5px;">
                              <li class="" v-if="!img.id">
                                <b-button variant="primary" @click="submit(img)"><i class="ri-upload-cloud-line p-0"></i></b-button>
                              </li>
                              <li>
                                <b-button variant="danger" @click="removeImage(img.id , index)"><i class="ri-delete-bin-line p-0"></i></b-button>
                              </li>
                              <li v-if="img.show_public">
                                <b-button variant="success" @click="showOrHideToPublic(img.id , index)"><i class="ri-eye-fill p-0"></i></b-button>
                              </li>
                              <li v-if="!img.show_public">
                                <b-button variant="info" @click="showOrHideToPublic(img.id , index)"><i class="ri-eye-off-line p-0"></i></b-button>
                              </li>
                            </ul>
                            <img class="img-fluid mb-1 rounded border" :src="img.path" alt="">
                        </div>
                    </div>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="3" class="profile-right">
              <MapCard />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
import MapCard from '@/components/hotelsComponents/MapCard.vue'
export default {
  components: {
    LayoutHeader,
    MapCard
  },
  data () {
    return {
      imgPreview: '',
      img: '',
      imgsList: []
    }
  },
  methods: {
    addInit () {
      this.$bvModal.show('img-modal')
    },
    submit (item) {
      const data = new FormData()
      data.append('image', item.image)
      this.addHotelImg(data).then(() => {
        item.id = 'erthj'
        this.getHotelImg()
      })
    },
    removeImage (id, index) {
      this.confermMsg(() => {
        if (id) {
          this.removeHotelImg(id).then(() => {
            this.getHotelImg()
            this.imgsList.splice(index, 1)
          })
        } else {
          this.imgsList.splice(index, 1)
        }
      })
    },
    showOrHideToPublic (id, index) {
      this.showOrHideImage(id).then(() => {
        this.getHotelImg()
      })
      this.getHotelImg().then(() => {
        this.imgsList = []
        this.hotelImgs.map(el => {
          this.imgsList.push({
            image: '',
            path: el.image_path,
            show_public: el.show_public,
            id: el.id
          })
        })
      })
    },
    getImg (event) {
      for (var key in event.target.files) {
        if (key !== 'item' || key !== 'length') {
          this.imgsList.unshift({
            image: event.target.files[key],
            path: URL.createObjectURL(event.target.files[key]),
            id: ''
          })
        }
      }
    }
  },
  mounted () {
    this.getHotelImg().then(() => {
      this.hotelImgs.map(el => {
        this.imgsList.push({
          image: '',
          path: el.image_path,
          show_public: el.show_public,
          id: el.id
        })
      })
    })
  }
}
</script>
<style>
.imgs-container.active{
    column-count: 3;
    column-gap: 5px;
}
.add-new-img {
    font-size: 18px;
    font-weight: bold;
    color: #ccc;
    height: 200px;
    border: 2px dashed #ccc;
    cursor: pointer;
}
.img-preview {
    height: 200px;
    border: 2px dashed #ccc;
}
.img-preview img {
    object-fit: contain;
}
</style>
